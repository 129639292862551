<template>
  <div id="team">
    <v-row>
      <v-col
        md="12"
        sm="12"
        xs="12"
        cols="12"
      >
        <v-card-text class="position-relative">
          <div
            v-if="hasAdvisor"
            class="mb-6"
          >
            <div class="page-title text-center">
              <h2
                class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center "
              >
                <span class="me-2 pb-5">My Advisor</span>
              </h2>
              <div>
                <v-card>
                  <div class="mt-4 ">
                    <v-row>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <h3 class="my-2">
                          {{ team.advisor.name }}
                        </h3>
                        <v-avatar
                          rounded
                          size="160"
                          color="white"
                          class="mr-3 ml-0"
                        >
                          <img
                            class="ml-auto mx-auto text-center d-all"
                            color="white"
                            height="120"
                            max-width="120"
                            lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
                            :src="team.advisor.mainImage"
                            @mouseover="team.advisor.mainImage = team.advisor.image"
                            @mouseleave="team.advisor.mainImage = team.advisor.toonimage"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <h3 class="my-2">
                          Power Ups:
                        </h3>
                        <div
                          v-for="(attr, index) in team.advisor.attributes"
                          :key="index"
                          class="mt-2"
                        >
                          <v-chip
                            outlined
                            :color="index|attrColor"
                            class="mt-1"
                          >
                            <span class="advisor-attr">{{ index }} +{{ attr }}%</span>
                          </v-chip>
                        </div>

                        <v-chip
                          v-if="team.advisor.countryBoost"
                          class="mt-4"
                          close-icon="mdi-close-outline"
                          color="grey"
                        >
                          Country Boost:
                          <country-flag
                            :country="team.advisor.countryBoost"
                            size="normal"
                          />
                        </v-chip>
                        <br>
                        <br>
                        <v-chip
                          outlined
                          close-icon="mdi-close-outline"
                          class="mt-1"
                        >
                          <span class="advisor-attr">
                            Fee <v-img
                              src="@/assets/images/buy/KombatCoinSilver.png"
                              max-width="18"
                              class="float-left mx-1 ml-2 mt-0"
                            ></v-img> {{ team.advisor.cost|toCurrencyNoDec }}</span>
                        </v-chip>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="hidden-sm-and-down"
                      >
                        <h3>
                          Bio:
                        </h3>
                        <p>
                          {{ team.advisor.bio || "Bio coming soon" }}
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
          <v-row class="mb-0 pb-0">
            <v-col
              md="12"
              sm="12"
              cols="12"
            >
              <h2
                v-if="team.advisor"
                class="text-center my-4"
              >
                Change Advisor
              </h2>
              <h2
                v-if="!team.advisor"
                class="text-center my-4"
              >
                Choose an Advisors
              </h2>

              <p class="text-center mb-0 pb-0">
                Team <strong>Advisors</strong> provide power ups and attribute enhancements based on
                their profile.
              </p>

              <v-row class="mt-0 pt-0 mb-0">
                <v-col
                  md="12"
                  sm="12"
                  cols="12"
                  class="mt-0 pt-0"
                >
                  <div
                    v-if="selectedAdvisor.name"
                    class="text-center mb-"
                  >
                    New Advisor: <strong>{{ selectedAdvisor.name }}</strong><br>
                    <div v-if="selectedAdvisor.id">
                      <v-chip
                        close-icon="mdi-close-outline"
                        color="grey"
                        class="my-3"
                      >
                        <div v-if="cost >= 0 && !team.advisor.name">
                          Cost
                        </div>
                        <div v-if="cost >= 0 && team.advisor.name">
                          Fee Difference
                        </div>
                        <div v-if="cost < 0">
                          Save:
                        </div>
                        <v-img
                          src="@/assets/images/buy/KombatCoinSilver.png"
                          max-width="18"
                          class="float-left mx-1 ml-2 mt-0"
                        ></v-img>
                        {{ cost|toCurrencyNoDec }}
                      </v-chip>
                    </div>
                  </div>
                  <p
                    v-if="!selectCheck"
                    class="warning text-center font-weight-semibold"
                  >
                    You need to select an
                    advisor
                    to save.
                  </p>
                  <p
                    v-if="!coinCheck"
                    class="warning text-center font-weight-semibold"
                  >
                    Not enough Silver Kombat
                    Coins.
                  </p>
                  <p class="text-center mb-0 pb-0">
                    <v-btn
                      v-if="selectedAdvisor.id"
                      color="success"
                      class="mt-3 mb-0 pb-0 text-center"
                      @click="saveAdvisor"
                    >
                      Hire advisor
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <div
      v-if="advisorList"
      max-width="1600"
      no-gutters
    >
      <v-slide-group
        show-arrows
        class="slider"
      >
        <v-slide-item
          v-for="(advisor, index) in advisorList"
          :key="index"
          v-slot="{active}"
        >
          <v-card
            :key="index"
            :input-value="active"
            active-class="success"
            class="ma-3 text-center d-flex flex-column advisor-card"
            height="420"
            width="220"
            @mousedown="selectedAdvisor = advisor"
          >
            <v-card-text>
              <h3 class="my-2">
                {{ advisor.name }}
              </h3>
              <v-avatar
                rounded
                size="80"
                color="white"
                class="mr-3 ml-0"
              >
                <img
                  :key="index"
                  class="ml-auto mx-auto text-center d-all"
                  color="white"
                  height="80"
                  max-width="80"
                  lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
                  :src="advisor.mainImage"
                  @mouseover="advisor.mainImage = advisor.image"
                  @mouseleave="advisor.mainImage = advisor.toonimage"
                />
              </v-avatar>
              <div
                v-for="(attr, index) in advisor.attributes"
                :key="index"
                class="mt-2 mb-2"
              >
                <v-chip
                  outlined
                  color="grey"
                  :class="index|attrColor"
                  class="mt-1"
                >
                  <span class="advisor-attr">{{ index }} +{{ attr }}%</span>
                </v-chip>
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions
              class="text-center d-all mb-2 mt-0 justify-center"
            >
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-chip
                    v-if="advisor.countryBoost"
                    close-icon="mdi-close-outline"
                    color="grey"
                  >
                    Country Boost:
                    <country-flag
                      :country="advisor.countryBoost"
                      size="normal"
                    />
                  </v-chip>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-chip
                    close-icon="mdi-close-outline"
                    color="grey"
                  >
                    Cost:
                    <v-img
                      src="@/assets/images/buy/KombatCoinSilver.png"
                      max-width="18"
                      class="float-left mx-1 ml-2 mt-0"
                    ></v-img>
                    {{ advisor.cost|toCurrencyNoDec }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
    <Dialogue :dialogue="dialogue"></Dialogue>
    <SnackBar :snackbar="snackbar"></SnackBar>
  </div>
</template>

<script>
import dbFunctions from '@/db'
import Dialogue from '@/views/components/Dialogue.vue'
import SnackBar from '@/views/components/SnackBar.vue'

export default {
  components: {
    Dialogue,
    SnackBar,
  },
  data() {
    return {
      team: [],
      user: this.$store.state.user,
      hasAdvisor: false,
      advisorList: [],
      selectedAdvisor: {},
      coinCheck: true,
      selectCheck: true,
      cost: 0,
      dialogue: {
        show: false,
        from: '',
        image: '',
        phrase: '',
      },
      snackbar: {
        show: false,
        text: '',
        color: 'text-warning',
      },

    }
  },
  watch: {
    selectedAdvisor() {
      this.selectCheck = true
      this.coinCheck = true
      if (this.team.name && this.team.advisor.cost) {
        this.cost = this.selectedAdvisor.cost - this.team.advisor.cost
      } else {
        this.cost = this.selectedAdvisor.cost
      }
    },
  },
  mounted() {
    this.getUser()
    this.getTeam()
    this.getAdvisors()
  },
  methods: {
    getUser() {
      dbFunctions.readUsersProfile(this.user.uid)
        .then(profileData => {
          this.user.profile = profileData
          localStorage.setItem('user', JSON.stringify(this.user))
          this.user.profile = profileData
        })
    },
    getTeam() {
      dbFunctions.getTeam(this.user.uid)
        .then(teamData => {
          this.team = teamData
          if (this.team.advisor) {
            if (this.team.advisor.length > 0) {
              this.hasAdvisor = true
            }
          }

          return teamData
        })
        .catch(error => {
          console.log(error)
        })
    },
    getAdvisors() {
      dbFunctions.getAdvisors()
        .then(querySnapshot => {
          this.advisorList = []
          querySnapshot.forEach(doc => {
            const data = doc.data()
            if (!data.archived) {
              data.id = doc.id
              this.advisorList.push(data)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    async saveAdvisor() {
      if (!this.selectedAdvisor) {
        this.selectCheck = false
      } else if (!this.user.profile.silverKombatCoins >= this.selectedAdvisor.cost) {
        this.coinCheck = false
      } else {
        let scoins = this.user.profile.silverKombatCoins
        if (this.cost > scoins) {
          // force coin check
          dbFunctions.readUsersProfile(this.user.uid)
            .then(profileData => {
              this.user.profile = profileData
              localStorage.setItem('user', JSON.stringify(this.state.user))
              this.$forceUpdate()
              if (this.cost > profileData.silverKombatCoins) {
                this.snackbar.text = 'You don\'t have enough coins :-('
                this.snackbar.show = true
                this.snackbar.color = 'text-warning'
              }
            })
        } else {
          scoins = this.user.profile.silverKombatCoins - this.cost
        }
        const pfields = {silverKombatCoins: scoins, silverKombatCoinsLastTrans: this.cost}

        const tfields = {advisor: this.selectedAdvisor}
        if (typeof this.cost === 'number' && scoins) {
          await dbFunctions.updateTeam(this.user.uid, tfields)
            .then(() => {
              const dialogue = {
                show: true,
                from: this.selectedAdvisor.name,
                image: this.selectedAdvisor.mainImage,
                phrase: this.$options.filters.hiredPhraseGen(this.selectedAdvisor.hiredPhrase, 'hired'),
                dialogueType: 'agentHired',
              }
              this.$store.state.user.profile.dialogue = dialogue
              dbFunctions.updateUsersProfile(this.$store.state.user, pfields)
                .then(() => {
                  this.$router.push({name: 'my-team', params: {dialogue}})
                })
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.logo-slider {
  cursor: pointer;
}

span.team-name {
  background: #000 !important;
  color: #ffff;
  padding: 2px;
  position: absolute;
  z-index: 2;
  bottom: 5px;
  width: 120px;
  height: 40px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
  border-radius: 0 0 4px 4px;
  display: inline-block;
  text-align: center;
  margin: auto;

}

span.team-text-wrapper {
  width: 110px;
  height: 30px;
  display: inline-block;
  margin-top: 6px;
  font-size: 12px;
}

span.team-text {
  text-align: center;
  font-size: 14px;
}

.team-avatar-img {
  margin-top: -35px;
  display: inline-block;
}

span.advisor-attr {
  text-transform: capitalize;
}

.v-card.advisor-card {
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px #ddd !important;
  transition: box-shadow 0.3s ease-in-out;
}

.v-card.advisor-card:hover {
  box-shadow: 0px 0px 16px #ddd !important;
  transition: box-shadow 0.3s ease-in-out;
}
</style>
